import React from "react";
import {Route, Routes} from "react-router-dom";
import {LandingPage} from "./pages/landingPage/LandingPage";
import {HomePage} from "./pages/HomePage";
import {Mint} from "./mint/mint";

export default function Pages() {
    return <Routes>
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/home" element={<HomePage/>}/>
        <Route path="/mint" element={<Mint/>}/>
    </Routes>;
}