import React from "react";
import "./community.css"

export function Community() {
    return (

        <div className="community_container">

            <blockquote className="callout quote EN"> In " The Valley ", Lawgiver Greybeard taught his class the
                Ultimate Law <u>#apefollowape</u> .

            </blockquote>


            <div className="community_title" id="community">
                JOIN OUR COMMUNITY
            </div>
            <div className="community-content">
                <div className="community_call">
                    <div className="community_text">
                        <div>
                            If you want to be part of the Bad Ape Unit project join us !
                            you will receive all the news about the project Future ... !
                        </div>
                        <br/>

                        <div>
                            Our future will be decided based on the opinions of the community,
                            and we will decide together which path to take.
                        </div>
                        <br/>
                        <div>
                            Holders possess commercial usage rights to their NFTs. You’re free to use your artwork as you
                            please.
                        </div>


                    </div>
                    <a href="https://linktr.ee/badapeunit" target="_blank"><button className="community_button"> JOIN US</button></a>

                </div>

                <img className="united-together" src="/UNITE SANS FRONTIERS.jpg" alt=""/>
            </div>



        </div>);
}