import React from "react";
import "./hero.css"
import {Route, Routes,Link} from "react-router-dom";

export function Hero() {
    return  (
        <div className="hero_container">


            <img className="hero_img"
                 src={screen.width > 820 ? "/BAD-APE-BANNER.png" : "/BAD-APE-BANNER-2.png"}
                 />

            <div className="hero_description">


                <img className="hero_description_img"
                     src="/image.gif"
                />
                <div className="hero_callout">

                    <p className="hero_title">

                        WELCOME TO THE <br/>
                        BAD APE UNIT


                    </p>
                    <p className="hero_text">
                        BAU is an alluring collection of 10001 unique NFTs, this Hand-Drawn Collection Was Made With Undivided Attention To Each Trait.
                        We hope the community find it dope.
                    </p>



                    <Link to="/mint">
                        <button className="hero_button mobile-hero"> Mint your BAD APE  </button>
                    </Link>

                </div>
            </div>





        </div>
)
}