import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../redux/data/dataActions";
import {Link} from "react-router-dom"
import "./mint.css"


import {connect} from "../redux/blockchain/blockchainActions";
import * as PropTypes from "prop-types";
import Web3 from "web3";
import Units from "ethereumjs-units";

export function Mint() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);


    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: "",
        SHOW_BACKGROUND: false,
    });


    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    return (
        <div className="mint_container">

            {blockchain.account ?
                <div>
                    <div
                        className="connectedWallet">Connected Wallet
                        : {Web3.utils.toChecksumAddress(blockchain.account.toLowerCase()).slice(0, 5)} ... {Web3.utils.toChecksumAddress(blockchain.account.toLowerCase()).slice(-4)}
                    </div>

                </div>
                : null}

            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? <SaleEnd Config={CONFIG}/> :
                <GetApe Supply={data.totalSupply}
                        CONFIG={CONFIG}
                        blockchain={blockchain}
                        getData={getData}
                        connect={connect}
                />}


        </div>
    )
}

function SaleEnd({Config}) {
    return (
        <div className="sale_end">

            <div>
                The initial sale has sold out. To get your BAD APE, check out the collection on OpenSea.
            </div>

            <a className="market-link" href={Config.MARKETPLACE_LINK}>{Config.MARKETPLACE_LINK}</a>
            <Link to="/home">
                <button className="hero_button">Back Home</button>
            </Link>

        </div>
    )
}


function ClaimedNFT({recipient}) {
    return (
        <div>
            transaction Hash {recipient.transactionHash}
            <div>
                {recipient && recipient.events && recipient.events.Transfer[0].returnValues.tokenId}
                {recipient && recipient.events && recipient.events.Transfer[1].returnValues.tokenId}

                {recipient && recipient.events && recipient.events.Transfer.map(item => {
                    <div>
                        you have bought : Bad APE #{item.returnValues.tokenId}
                    </div>
                })}
            </div>
        </div>
    );
}


function Connect({blockchain, CONFIG, getData, connect, gasLimit, gasPrice}) {
    const dispatch = useDispatch();
    const [mintAmount, setMintAmount] = useState(1);
    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };
    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > 7) {
            newMintAmount = 7;
        }
        setMintAmount(newMintAmount);
    };
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);

    const [isMobile, setIsMobile] = useState(false);
    const [isMetamask, setIsMetamask] = useState(false);


    const claimNFTs = () => {
        let cost = CONFIG.WEI_COST;

        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        try {
            blockchain.smartContract.methods
                .publicSalesMint(mintAmount)
                .send({
                    gasLimit: String(totalGasLimit),
                    gasPrice: Units.convert(gasPrice, 'gwei', 'wei'),
                    to: CONFIG.CONTRACT_ADDRESS,
                    from: blockchain.account,
                    value: totalCostWei,
                })
                .once("error", (err) => {

                    if (err && err.code === 4001) {
                        // Error: User Denied MetaMask Transaction.
                        setFeedback("Click buy to mint your NFT.");
                    } else {
                        setFeedback("Sorry, something went wrong, please try again later.");
                    }


                    setClaimingNft(false);
                })
                .then((receipt) => {
                    console.log(receipt);
                    setFeedback(
                        `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
                    );


                    setClaimingNft(false);
                    dispatch(fetchData(blockchain.account));
                }).catch((error) => {
                // If the request fails, the Promise will reject with an error.

            });
        } catch (e) {
            console.log(e);
        }

    };
    const claimFreeNFTs = () => {
        let cost = CONFIG.WEI_COST;

        let totalCostWei = String(cost / 21 * 5);
        let totalGasLimit = String(gasLimit );
        console.log("Cost: ", totalCostWei);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Minting your " Welcome " ${CONFIG.NFT_NAME}...`);
        setClaimingNft(true);
        setMintAmount(1);
        try {
            blockchain.smartContract.methods
                .welcomeMint()
                .send({
                    gasLimit: String(totalGasLimit),
                    gasPrice: Units.convert(gasPrice, 'gwei', 'wei'),
                    to: CONFIG.CONTRACT_ADDRESS,
                    from: blockchain.account,
                    value: totalCostWei,
                })
                .once("error", (err) => {

                    if (err && err.code === 4001) {
                        // Error: User Denied MetaMask Transaction.
                        setFeedback("Click Welcome option to mint your NFT.");
                    } else {
                        setFeedback("Sorry, something went wrong, please try again later.");
                    }


                    setClaimingNft(false);
                })
                .then((receipt) => {
                    console.log(receipt);
                    setFeedback(
                        `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
                    );


                    setClaimingNft(false);
                    dispatch(fetchData(blockchain.account));
                }).catch((error) => {
                // If the request fails, the Promise will reject with an error.

            });
        } catch (e) {
            console.log(e);
        }

    };

    useEffect(async () => {
        try {

            const networkId = await ethereum.request({
                method: "net_version",
            })

            if (networkId == CONFIG.NETWORK.ID) {

                console.log("You have connected to the POLYGON Mainnet")


            } else {
                await ethereum.request({method: 'wallet_switchEthereumChain', params: [{chainId: '0x89'}]}).then(() => {
                        dispatch(connect());
                        getData();
                    }
                )
            }

        } catch (e) {
            console.log(e)
        }
    }, [blockchain.errorMsg]);
    const addressRef = useRef()


    const check = (e) => {

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
            (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform))) {
            setIsMobile(true)

            if (typeof window.ethereum !== 'undefined'
                || (typeof window.web3 !== 'undefined')) {
                setIsMetamask(true)

                e.preventDefault();
                dispatch(connect());
                getData();


            } else {

                addressRef.current.click();
                //window.open('https://metamask.app.link/dapp/badapeunit.com/','_blank');
                setIsMetamask(false)
            }


        } else {

            e.preventDefault();
            dispatch(connect());
            getData();
            setIsMobile(false)
            setIsMetamask(false)
        }
    }


    return (
        <>

            {blockchain.account === "" ||
            blockchain.smartContract === null ?
                (<div className="connect_me">
                    <div className="connect_me_text">Connect to the {CONFIG.NETWORK.NAME} network</div>
                    <button className="hero_button" onClick={check}>Connect</button>


                    <a ref={addressRef} className="gotoMeta"
                       href="https://metamask.app.link/dapp/badapeunit.com/mint"> Go To MetaMask</a>
                    <div className="connect_me_error">
                        {blockchain.errorMsg !== "" ? (
                            <>
                                {blockchain.errorMsg
                                }

                            </>
                        ) : null}
                    </div>

                </div>) :
                (<div className="buy-section">
                    <div className="ape-block">
                        <button
                            className="hero_button quantity"
                            style={{lineHeight: 0.4}}
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                decrementMintAmount();
                            }}
                        > {"<"} </button>

                        <div className="mc-content__display">
                            <p className="mc-title">Quantity</p>
                            <span> {mintAmount} NFTs</span>
                        </div>

                        <button
                            className="hero_button quantity"
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                                e.preventDefault();
                                incrementMintAmount();
                            }}
                        >
                            >
                        </button>

                    </div>
                    < div className="feedback">  {feedback}</div>

                    <button
                        className="hero_button"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                            e.preventDefault();

                            claimNFTs();
                            getData();
                        }}
                    >
                        {claimingNft ? "BUSY" : "BUY"}
                    </button>

                    <button
                        className="hero_button freemint"
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                            e.preventDefault();

                            claimFreeNFTs();
                            getData();
                        }}
                    >
                       Welcome Mint Option
                    </button>

                    <div className="freemintnote"><span style={{fontWeight:800}}>NB/</span> Due to free mint <span style={{fontWeight:400 , color: "var(--primary)"}}>" ABUSE "</span>  , the cost of the Welcome mint option has been raised to <span className="balance-acquired ">5 MATIC</span> .</div>

                </div>)
            }
        </>
    );
};

function GetApe({CONFIG, Supply, blockchain, getData, connect}) {

    const [balance, setBalance] = useState(0);
    const [gasFees, setGasFees] = useState({FastGasPrice: 100})
    const [gasLimit, setGasLimit] = useState(4000000)
    const MINUTE_MS = 30000;
    useEffect(() => {

        getGas()
        const interval = setInterval(() => {
            getGas()
        }, MINUTE_MS);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])
    const getGas = async () => {

        await fetch("https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=73AEP18DASH2WEIJRDNRBIXDAW2VNUPJ8F")
            .then(res => {
                return res.json()
            })
            .then(async res => {
                let result = res.result
                setGasFees(result)
                setGasLimit(((result.FastGasPrice) * 21000).toFixed(0))

            })


            .catch(e => console.log(e))

    }

    useEffect(() => {
        blockchain.account && blockchain.smartContract && blockchain.smartContract.methods.balanceOf(blockchain.account).call(function (err, res) {


            setBalance(res)

        }).catch((error) => {
            // If the request fails, the Promise will reject with an error.
            console.log(error)
        });

    })


    return (


        <div className="ape-container">

            <div className="ape-left-container">

                <div className="ape-image">
                    <img src="/ape.gif" alt=""/>
                </div>

                {Supply > 0 ? <span className="ape-minted">   Minted {Supply} / {CONFIG.MAX_SUPPLY}    </span> : ""}

                <h2 className="ape-title">
                    Max 7 NFTs <br/>
                    per Wallet


                </h2>
                <div className="ape_buy">
                    1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                    {CONFIG.NETWORK.SYMBOL}
                </div>
                <div className="balance desktopape">
                   Welcome option costs {" 5 "}
                    {CONFIG.NETWORK.SYMBOL}
                </div>
                <div className="balance">
                    {<div>You Have Acquired : <span className="balance-acquired">{balance} BAU</span></div>}
                </div>
                <div className="gasfees">Suggested Gas Price : <span className="gwei">{gasFees.FastGasPrice}</span> Gwei
                </div>

            </div>


            <div className="ape-right-container">
                <div className="ape-title-right">Mint BAD APE UNIT is <span className="lightred">Live !</span></div>

                <Connect gasLimit={gasLimit} gasPrice={gasFees.FastGasPrice} blockchain={blockchain} CONFIG={CONFIG}
                         getData={getData} connect={connect}/>

            </div>


        </div>


    )
}