import React from "react";
import "./app.css"
import Routes from "./Routes";

function  App () {
    return (
        <div className="app_container">
            <Routes/>
        </div>
        )

}
export default  App