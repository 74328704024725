import React from "react";
import "./footer.css"
import {Link} from "react-router-dom";

export function Footer() {
    return (
        <div>
            <div className="Footer_container" >
                <div className="Footer_title">
                    AS SEEN ON
                </div>
                <div className="featuredContainer">
                    <a className="MoonlyLink" href="https://moon.ly/nft/bad-ape-unit"
                       target="_blank"

                       style={{"text-decoration": "none;"}}>

                        <img src="https://moon.ly/_next/image?url=%2Flogo%2Flogo-with-name.png&w=3840&q=75" data-tip="moonly"
                             alt="moonly" title="Moonly Logo" width="250px" border="0"/>

                    </a>
                    <a href="https://nftcalendar.io/event/badapeunit/"
                       target="_blank"
                       style={{"text-decoration": "none;"}}>
                        <img className="vc_single_image-img "
                             src="\nft_calendar--400x400.png"
                             alt="nft calendar Logo" title="NFT Calendar Logo" width="250"  />
                    </a>


                </div>

            </div>
            <div className="footer">

                <span>© 2022 SaiyLeon ART  <br/> badapeunit ® All rights reserved. <br/> <span>Terms and conditions</span>  </span>



                <span>
                <a href="https://opensea.io/collection/badapeunit" target="_blank">
                      <img className="signature" src="/saiyleonsign.png" alt="link to opensea"/>
                 </a>

            </span>
            </div>
        </div>


    )
}