import React, {useEffect, useState} from "react";
import "./contractAddress.css"

export function ContractAddress() {
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
    });
    const  [hint , showHint] = useState(false)
    const onClick =  () => {
        showHint (!hint)

    }
    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };
    useEffect(() => {
        getConfig();
    }, []);
    return (
        <div className="address_container">


                 <div>
                     VERIFIED SMART CONTRACT ADDRESS :
                 </div>
                <a className="contract_address" href={CONFIG.SCAN_LINK} target="_blank">
                    {CONFIG.CONTRACT_ADDRESS}
                </a>




            <div className={`clue_hint ${hint? "show_hint" : ""}`} onClick={onClick}>
                N.B. At special balanced points, legends will raise and a new era will flourish , then Homininae shall
                rule .
            </div>
        </div>);
}