import React from "react";
import "./roadmap.css"

function Stages() {
    return (
        <div className="stages_container">

                <div className="stages">


                    <div className="stages_percentage div1">Phase 1</div>
                    <div className="stages_text div2"> Build a Community while releasing the mint in 13 stages  <br/>Each with
                        significant rewards for holders. </div>
                    <div className="stages_percentage div3">Phase 2</div>
                    <div className="stages_text div4"> Reveal The Gallery.</div>
                    <div className="stages_percentage div5">Phase 3</div>
                    <div className="stages_text div6"> Prizes to reward holders and those who has decoded the collection and spotted rare items and hints.
                    </div>
                    <div className="stages_percentage div7">Phase 4</div>
                    <div className="stages_text div8">
                        Pre-lunch of unite sans frontieres,
                        ( I suggest to hold one nft in the worst case ).

                    </div>
                    <div className="stages_percentage div9">Phase 5</div>
                    <div className="stages_text div10">Lunching unite sans frontieres .
                    </div>
                    <div className="stages_percentage div11">Phase 6</div>
                    <div className="stages_text div12">If we made it this far well ... We are already a successful Brand
                        and valued NFT collection.
                    </div>


                </div>



        </div>

    );
}

;

export function RoadMap()
{
    return (
        <div className="roadmap_container">

            <blockquote className="callout quote EN"> BAU strives to be the sexiest collection alive on the polygon blockchain.

            </blockquote>
            <div className="roadmap_title" id="roadmap">THE ROADMAP</div>


            <Stages/>


        </div>
    );
}