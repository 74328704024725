import React, {useState} from "react";
import {Link} from "react-router-dom";

import "./Header.css"


export function Header() {

    const [isOpen , setIsOpen] = useState(false)
    function gotosection(section) {
        setIsOpen(false)
        document.getElementById(section).scrollIntoView({ behavior: 'smooth' })
    }

    const  onClickBurger = () =>{
    setIsOpen(!isOpen);
    }

    return  (
        <div className="Header_container">
            <div className="section-logo">
                <Link  to="/">
                    <img className="logo_img"
                         src="./LOGO-BAU.png" alt="bau header logo"
                    />
                </Link>

            </div>


            <Link to="/mint">
                <span  className="section-link mint">MINT</span>
            </Link>

                    <span onClick={(e) => gotosection("roadmap")} className="section-link links">ROADMAP</span>

                    <span onClick={(e) => gotosection("community")} className="section-link links">COMMUNITY</span>
                <span onClick={(e) => gotosection("specs")} className="section-link links">SPECS</span>


                    <div id="burger" onClick={onClickBurger} className={ isOpen ? "open-main-nav is-open" : "open-main-nav"}>
                        <span className="burger"></span>

                    </div>
                    <nav className={isOpen ? "main-nav is-open" : "main-nav"} id="is-open">
                        <ul>
                            <li>
                                <a onClick={(e) => gotosection("roadmap")}>ROADMAP</a>
                            </li>

                            <li>
                                <a onClick={(e) => gotosection("community")}>COMMUNITY</a>
                            </li>
                            <li>
                                <a onClick={(e) => gotosection("specs")}>SPECS</a>
                            </li>

                        </ul>
                    </nav>



        </div>
)
}