import React from "react";
import "./specs.css"

export function Specs() {
    return (
        <div className="specs_container"  id="specs">
            <div className="specs_title">
                THE SPECS
            </div>
            <div className="specs_content">

                <div>
                    <div className="specs_text">


                        The Bad Ape Unit collection is randomly generated from over 200 unique traits,
                        such as Cloths, Hair Style, Glasses, and more ensuring each NFT features a completely unique design.
                        every member of the unit is rare in his own way, but some are rarer than others.
                        <br/>
                        <br/>
                    </div>
                    <div className="specs_text">
                            Those bad boys are stored as ERC-721 tokens on the Polygon blockchain and hosted on IPFS.

                    </div>




                </div>
                <img className="specs_img" src="/ape.gif" alt=""/>
            </div>



        </div>
    )
}