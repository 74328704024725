import React from "react";
import "./landingPage.css"
import {Link} from "react-router-dom";
import {SocialIcon} from "react-social-icons";
import {Filter} from "../../filter/filter";

function MiniLogo() {
    return (
        <>
            <img className="small_logo" src="./LOGO-BAU-2.png" alt=""/>
        </>
    );
}

function SocialAccounts() {
    return (
        <div className= "landing-page-header">
        <div className="social_accounts">
            <SocialIcon url="https://twitter.com/VHoneim" style={{maxHeight:"30px" , maxWidth:"30px"}}/>
            <SocialIcon url="https://discord.com/channels/831287358355275877/831287365988384881" style={{maxHeight:"30px" , maxWidth:"30px"}}/>
            <SocialIcon url="https://www.instagram.com/saiyleon/" style={{maxHeight:"30px" , maxWidth:"30px"}}/>
        </div>
        </div>
    );
}

export function LandingPage() {
    return (
        <div className="landing-page-container">


            <div className="welcome  ">
                WELCOME

            </div>
            <Link to="./home">
                <button className="hero_button" >GET ME IN NOW </button>
            </Link>
             <div className="separator"/>
            <div className="about">
                <MiniLogo/>
                <p className="BAU_description  ">
                    A collection of 10001 unique NFTs that live on the Polygon Blockchain.

                </p>
                <MiniLogo/>


            </div>

            <a  href="https://www.instagram.com/saiyleon/" target="_blank" >
                <img className="saiyleon" src="/saiyleonsign.png" alt=""/>
            </a>
        </div>

    );
}