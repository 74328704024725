import {Header} from "../Header/Header";
import {Hero} from "../heroLanding/Hero";
import {RoadMap} from "../roadmap/RoadMap";
import {Community} from "../community/Community";
import {Specs} from "../specs/specs";

import {ContractAddress} from "../contractAddress/ContractAddress";
import React from "react";
import "./homepage.css"
import {Footer} from "../copyrights/footer";

export function HomePage() {
    return <div className="homepage-container">
        <Header/>
        <div className="homepage-container-page">
            <Hero/>
            <RoadMap/>

            <Community/>
            <Specs/>
            <ContractAddress/>
            <Footer/>
        </div>

    </div>;
}